
import { useStores } from '@/store/Stores';
import { onBeforeUnmount } from 'vue';

export default {
  setup() {
    const { accountStore, headerStore, settingsStore } = useStores();

    onBeforeUnmount(() => {
      headerStore.setTabs('');
    });

    return {
      accountStore,
      settingsStore,
      headerStore
    };
  },
  async created() {
    const { accountStore, headerStore, settingsStore } = useStores();

    await accountStore.init();

    settingsStore.init('bg', 'companyBackgrounds');
    settingsStore.init('logos', 'companyLogos');
    settingsStore.init('banners', 'companyBanners');

    const isChief = accountStore.state.data.role === 'chief';

    const tabs = [
      { label: 'tabs.settings_basic', to: { name: 'SettingsBasic' } },
      { label: 'tabs.settings_bg', to: { name: 'SettingsBG' } },
      { label: 'tabs.settings_export', to: { name: 'SettingsExport' } }
    ];

    headerStore.setTabs(isChief ? tabs : '');
  }
};
